'use client';

import { Button } from '@canvaza/serval-ui';
import clsx from 'clsx';
import { getCookie } from 'cookies-next';

export default function Error({ reset }: { _error: Error; reset: () => void }) {
  const isDark = getCookie('isDark');

  return (
    <html
      className={clsx({
        dark: isDark,
      })}
    >
      <head></head>
      <body
        className={clsx(
          'flex h-screen items-center justify-center bg-dark-lighter text-dark-darker antialiased dark:bg-dark dark:text-dark-lighter '
        )}
      >
        <div
          className="flex flex-col items-center justify-center text-error"
          role="alert"
        >
          <h2 className="text-lg font-semibold">
            Oops, something went wrong :
          </h2>
          <Button
            variant="contained"
            className="mt-4"
            onClick={() => {
              reset();
            }}
          >
            Try Again
          </Button>
        </div>
      </body>
    </html>
  );
}
